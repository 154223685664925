;
(function ($) {
    $(document).ready(function () {

        $(".phone-number").mask("+7(999) 999-9999");

        var height = 100;
        var margin = 0;
        var $elem = $('#nav');
        var $body = $('body');

        var checkScroll = function() {
            var top = $(window).scrollTop();
            if (top > 0) {
                $body.addClass('active');
            }
            else {
                $body.removeClass('active');
            }
            if (top + margin < height) {
                $elem.css('top', (height - top));
            } else {
                $elem.css('top', margin);
            }
        };

        $(function () {
            $(window).scroll(function () {
                checkScroll();
            });
        });

        checkScroll();

        $(document).on('input', '#square, #count', function () {
            if (prices === undefined) {
                return;
            }
            var square = parseInt($('#square').val());
            var count = parseInt($('#count').val());
            var price;
            var total;
            var $total = $('#total span');

            if (square <= 0 || !square) {
                return;
            }

            if (square <= 10) {
                price = prices[3];
            } else if (square >= 11 && square <= 20) {
                price = prices[2];
            } else if (square >= 21 && square <= 50) {
                price = prices[1];
            } else if (square >= 51) {
                price = prices[0];
            }

            total = price * square;

            if (count && count > 0) {
                total += count * 150;
            }

            $total.text(total + 'р.');

        });

        var $inputFields = $('.placeholder input[type="text"], .placeholder textarea');

        $inputFields.each(function () {
            if ($(this).val() != '') $(this).css({backgroundColor: '#fff'});
        });
        $inputFields.blur(function () {
            if ($(this).val() == '') $(this).css({backgroundColor: 'transparent'});
        });
        $inputFields.focus(function () {
            $(this).css({backgroundColor: '#fff'});
        });
        $inputFields.change(function () {
            if ($(this).val() != '') $(this).css({backgroundColor: '#fff'});
        });
        $inputFields.mouseover(function () {
            if ($(this).val() != '') $(this).css({backgroundColor: '#fff'});
        });

        $('#main-slider').owlCarousel({
            items: 1,
            nav: true
        });

        $('.gallery-slider').owlCarousel({
            margin: 10,
            items: 3,
            nav: true,
        });

        (function () {
            var $links;
            var $tabs;
            var $inner;

            $('.tabs-links li a').click(function (e) {
                e.preventDefault();
                var $el = $(this);
                var index = $el.parent().index();
                var width;
                $links = $el.closest('.tabs-links');
                $tabs = $links.next();
                $inner = $tabs.find('.tabs-inner');
                width = $inner.find('.tab').first().width() + 18;

                $inner.css({'left': -index * width});
                $links.find('.active').removeClass('active');
                $el.addClass('active');
            });
        })();

        $modalOverlay = $('.modal-overlay');
        $modalWindow = $('.modal-window');
        $openModal = $('.open-modal');
        $body = $('body');
        $html = $('html');

        $modalOverlay.hide();
        $modalWindow.hide();
        $modalWindow.click(function (e) {
            e.stopPropagation();
        });

        $openModal.click(function (e) {
            e.preventDefault();
            var $el = $(this);
            var $win = $($el.attr('href'));

            if ($el.attr('data-theme')) {
                $win.find('form input[name="theme"]').val($el.attr('data-theme'));
            }

            openModal($win);
        });

        $modalOverlay.click(function () {
            closeModal();
        });
    });
})(jQuery);

var $modalOverlay;
var $modalWindow;
var $openModal;
var $body;
var $html;

var closeModal = function () {
    $modalWindow.css({'display': 'none'});
    $modalOverlay.css({'display': 'none'}).children().appendTo($body);
    $html.css({'overflow': 'auto', 'width': 'auto'})
};
var openModal = function ($el) {
    $el.appendTo($modalOverlay).css({'display': 'block'});
    $html.css({'overflow': 'hidden', 'width': $html.outerWidth()});
    $modalOverlay.css({'overflow-y': 'scroll', 'display': 'block'});
};

var sendForm = function (elem) {
    var $form = $(elem);
    var uri = $form.attr('action');
    var str = "";
    var error = 0;
    $form.find('.rfield').each(function () {
        var $input = $(this);
        if ($input.val().length == 0) {
            error++;
            $input.addClass('invalid');
        }
        else {
            $input.removeClass('invalid');
        }
    });
    if (error == 0) {
        $form.find('input[type="text"], input[type="hidden], textarea').each(function () {
            var $input = $(this);
            str += $input.attr('name') + '=' + $input.val() + '&';
        });
        var Data = str.substring(0, str.length - 1);
        $.ajax({
            url: uri,
            async: true,
            type: 'POST',
            data: Data,
            processData: false,
            contentType: 'application/x-www-form-urlencoded',
            dataType: 'HTML',
            success: function (data, textStatus, xhr) {
                if (data.length > 0) {
                    $form.find('input[type="text"], input[type="hidden"], textarea').each(function () {
                        var $input = $(this);
                        $input.val('').removeClass('invalid').removeAttr('style');
                    });
                    closeModal();
                    openModal($('#thanx'));
                    setTimeout(function () {
                        closeModal()
                    }, 2000);
                } else {
                    alert('Ваша заявка не принята! Возможно вы не заполнили все поля!');
                }
            },
            dataFilter: function (data, dataType) {
                return data;
            }
        });
    }
};